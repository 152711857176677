<template>
  <v-dialog
      :value="value"
      @input="emitInput"
      width="800"
      @click:outside="newPhotoPreview = null"
  >

    <v-card>
      <v-toolbar dense color="primary" dark>
        <v-toolbar-title>
          {{ $t("home.background.title") }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pa-4">
        <v-form ref="formUploadBackground" v-model="valid">
          <div class="subtitle">{{ $t("home.background.predefined")}}</div>
          <div class="d-flex flex-row flex-nowrap mt-4">
            <color v-for="(color, i) in allVariants" :key="i" :color="color"
                   @click.native="changeBackground({type: 'predefined', val: color})" class="mr-4"
                   :class="{selected: color == backgroundColor}"></color>
          </div>
          <br>
          <br>
          <div class="subtitle">{{ $t("home.background.subtitle") }}</div>
          <v-file-input ref="backgroundField" @change="changeBackground" :label="$t('account.fields.searchFiles')"
                        accept="image/*"
                        @click:clear="$refs.formUploadBackground.reset();newPhotoPreview = null "
                        @blur="!photo ? $refs.formUploadBackground.reset() : ''"
                        :rules="ValidationRules.wallpaper">
          </v-file-input>
          <div class="d-flex flex-column align-center" width="100%">
            <img v-if="actualBackground && !newPhotoPreview" :src="actualBackground" width="256"
                 class="d-inline-block mb-8 elevation-16" alt="Current photo preview">
            <img v-if="newPhotoPreview" :src="newPhotoPreview" width="256" class="d-inline-block mb-0 elevation-16" alt="New photo preview">
            <v-btn v-if="actualBackground" :loading="deleting" @click="deleteBackground" class=" mb-8" color="error">{{ $t("actions.delete") }}</v-btn>
          </div>
          <p v-if="!actualBackground && !newPhotoPreview">{{ $t("home.background.noBackground") }}</p>
          <div class="d-flex">
            <v-spacer></v-spacer>
            <v-btn color="primary" :disabled="!newPhotoPreview" :loading="loading" @click.prevent="saveBackground">{{ $t("actions.save") }}</v-btn>
            <v-spacer></v-spacer>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import PlaneteOnlineService from "@/Services/PlaneteOnlineService"
import UserService from "@/Services/UserService"
import Vuex from "vuex"
import Images from "@/Utils/Images"
import ValidationRules from "@/Mixins/ValidationRules"

export default {
  name: "ChangeBackground",
  mixins: [ValidationRules],
  components: {
    Color: () => import("@/Components/Commons/UiElements/Color")
  },
  props: {
    value: Boolean
  },
  computed: {
    ...Vuex.mapState(["preferences"]),
    actualBackground() {
      if (this.backgroundType == "predefined") {
        return require("@/Assets/Images/Interface/Backgrounds/predefined-background-" + this.preferences.background.value + "-" + (this.$vuetify.theme.dark ? 'dark' : 'light') + ".png")
      } else if (this.backgroundType == "custom") {
        return this.preferences.background.value
      } else
        return null
    },
    backgroundType() {
      if (this.preferences.background) {
        return this.preferences.background.type
      } else {
        return null
      }
    }
  },

  data() {
    return {
      allVariants: ["red", "pink", "purple", "deepPurple", "indigo", "cyan", "teal", "green", "lime", "amber", "orange"],
      backgroundColor: "",
      photo: null,
      newPhotoPreview: null,
      photoUpdated: false,
      loading: false,
      oldWallpaper: null,
      valid: false,
      deleting: false
    }
  },
  methods: {
    async deleteBackground() {
      this.deleting = true
      await this.sleep(1000)
      if (this.backgroundType == "predefined") {
        this.$store.commit("setUser", {background: null})
        let store = this.$_.pick(this.$store.state, ["preferences"])
        PlaneteOnlineService
            .postInterface(store)
            .then(() => {
              this.$nSuccess(this.$t("home.background.backgroundDeleted"))

            })
      } else {
        let response = await UserService.deleteWallpaper()

        if (response) {
          this.newPhotoPreview = null
          this.photo = null
          this.$store.commit("setUser", {background: null})
          this.$nSuccess(this.$t("home.background.backgroundDeleted"))
        } else {
          this.$nError(this.$t("home.background.backgroundNotDeleted"))
        }
      }
      this.deleting = false
    },
    emitInput: function (val) {
      this.$emit('input', val)
    },
    async changeBackground(data) {
      if (data != null) {
        if (data.type == "predefined") { // Si c'est une image prédéfinie
          this.backgroundColor = data.val
          this.newPhotoPreview = require("@/Assets/Images/Interface/Backgrounds/predefined-background-" + this.backgroundColor + "-" + (this.$vuetify.theme.dark ? 'dark' : 'light') + ".png")
          this.$refs.backgroundField.reset();
          this.photo = data
        } else { // Si c'est une image personnalisée (i.e. Venant du PC de l'utilisateur)
          let photo = data
          this.newPhotoPreview = await Images.toBase64(photo)
          this.photo = photo
          this.photoUpdated = true
          this.backgroundColor = null
        }
      }
    },
    async saveBackground() {
      this.loading = true
      if (this.photo && this.photo.type == "predefined") {
        await this.sleep(1500)

        this.$store.commit("changeBackground", {type: "predefined", value: this.backgroundColor})
        let store = this.$_.pick(this.$store.state, ["preferences"])
        await PlaneteOnlineService.postInterface(store)
        this.emitInput(false)

      } else {

        if (this.$refs["formUploadBackground"].validate()) {
          await this.sleep(1500)

          let formDataWallpaper = new FormData()
          formDataWallpaper.append("file", this.photo)
          let b64 = await Images.toBase64(this.photo)
          let store = this.$_.pick(this.$store.state, ["preferences"])
          store.preferences.background = null
          let responseWallpaper = await UserService.postWallpaper(formDataWallpaper)
          if (responseWallpaper == "OK")
            this.$store.commit("changeBackground", {type: "custom", value: b64})
          await PlaneteOnlineService.postInterface(store)

          this.emitInput(false)

        }
      }

      this.newPhotoPreview = null
      this.loading = false
    }
  }
}
</script>

<style scoped>
.selected {
  border: 2px solid black;
}
</style>